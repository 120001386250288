import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeroesService, Heroe } from "../../services/heroes.service";

@Component({
  selector: 'app-heroe',
  templateUrl: './heroe.component.html'
})
export class HeroeComponent implements OnInit {

  private _Heroe : Heroe;

  constructor( private _ActivatedRoute:ActivatedRoute,
               private _HeroesService:HeroesService
  ) { 
    this._ActivatedRoute.params.subscribe( params => {
      // Lee el parámetro id y obtiene el héroe del servicio.
      this._Heroe = this._HeroesService.getHeroe(params['id']);
    })
  }

  ngOnInit() {
  }

}
