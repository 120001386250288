import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  constructor( private _Router:Router ) { }

  ngOnInit() {
  }

  buscarHeroe(filtro:string) {
    console.log(filtro);
  }

  navegarBuscar(filtro:string) {
    this._Router.navigate(['/buscar', filtro]);
  }

}
