import { Component, OnInit } from '@angular/core';
import { HeroesService, Heroe } from "../../services/heroes.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html'
})
export class HeroesComponent implements OnInit {

  heroes:Heroe[];

  constructor( private _HeroeService:HeroesService,
               private _Router:Router ) { 
                
  }

  ngOnInit() {
    this.heroes = this._HeroeService.getHeroes();
    //console.log(this.heroes);
  }

  navegarHeroe( id:number ) {
    this._Router.navigate(['/heroe', id]);
  }

}
