import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Router } from "@angular/router";

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.css']
})
export class TarjetaComponent implements OnInit {

  @Input() _Heroe : any = {};
  @Input() _Indice : number;

  @Output() HeroeSelec : EventEmitter< number >;

  constructor( private _Router:Router ) { 
    this.HeroeSelec = new EventEmitter();
  }

  ngOnInit() {
  }

  navegarHeroe() {
    this._Router.navigate(['/heroe', this._Indice]);
    //this.HeroeSelec.emit(this._Indice);
  }

}
