import { Component, OnInit } from '@angular/core';
import { HeroesService, Heroe } from "../../services/heroes.service";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styles: []
})
export class BuscarComponent implements OnInit {

  private _Heroes: Heroe[];
  private _Filtro : string;

  constructor( private _HeroesService:HeroesService,
               private _ActivatedRoute:ActivatedRoute,
               private _Router:Router ) { 

    this._ActivatedRoute.params.subscribe( params => {
      this._Filtro = params['filtro'];
      this._Heroes = this._HeroesService.buscarHeroes(this._Filtro);
    })
  }

  ngOnInit() {
    
  }

  /*navegarHeroe( id:number ) {
    this._Router.navigate(['/heroe', id]);
  }*/

}
